/**
 * Timetastic
 * Features > Timetastic Pro page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"
import Helmet from "react-helmet"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import FeaturesCrossLinks from "src/components/featuresCrossLinks"
import FeaturesSingleQuote from "src/components/featuresSingleQuote"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrialAlt from "src/components/freeTrialAlt"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import SignupButton from "src/components/signupButton"

// Styles
import "src/styles/templates/features.scss"

// SEO
const title = "Timetastic Pro"
const description =
  "Business class features, security and support. Peace of mind with additional security. Better informed with powerful insights, add guaranteed uptime and priority support and you’ve everything you’ll need. Timetastic Pro is built for growing businesses like yours."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} useTitleTemplate={false} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-features-main">
          <section className="c-section">
            <div className="u-inner u-inner--l">
              <div className="c-features-grid__item c-features-intro is-unanimated">
                <div className="c-features-intro__description">
                  <h1 className="h h2 c-features-intro__title">
                    Timetastic Pro
                  </h1>
                  <p className="c-features-intro__summary">
                    Timetastic Pro has better security, powerful insights and
                    the unique Burnout Board. It’s got a slick Microsoft Teams
                    integration, HR fields and company digests. It’s a huge
                    upgrade for your team.
                  </p>
                  <p>
                    Say hello to Timetastic Pro - built for growing businesses
                    like yours.
                  </p>

                  <p>
                    <SignupButton
                      buttonCopy="Start a trial with Pro"
                      startWithPro="true"
                      trackCtaLabel="PricingProHero"
                    />
                  </p>
                </div>
                <div className="c-features-grid__media c-features-grid__media-pro-1">
                  <img
                    loading="eager"
                    alt="Absence at a glance"
                    className="c-features-grid__image"
                    src="/images/features/timetastic-pro/at-a-glance.png"
                  />
                  <img
                    loading="eager"
                    alt="Burnout Board"
                    className="c-features-grid__image"
                    src="/images/features/timetastic-pro/burnout-board-mini.png"
                  />
                  <img
                    loading="eager"
                    alt="Absence Insights"
                    className="c-features-grid__image"
                    src="/images/features/timetastic-pro/absence-insights-mini.png"
                  />
                  <img
                    loading="eager"
                    alt="Timetastic Pro"
                    className="c-features-grid__media-icon c-features-grid__media-icon--left"
                    src="/images/features/timetastic-pro/pro-icon.svg"
                  />
                </div>
              </div>
            </div>
          </section>

          <FeaturesSingleQuote
            quote={{
              avatar:
                "/images/features/timetastic-pro/matt-barker-jetstack.jpg",
              company: "Jetstack",
              name: "Matt Barker",
              quote:
                "There’s a lot of love for Timetastic in Jetstack, it’s almost gained a cult following amongst the team!",
            }}
          />

          <section className="c-section c-features-xyz">
            <div className="u-inner u-inner--l">
              <div className="c-features-grid__item">
                <div className="c-features-grid__media">
                  <img
                    loading="eager"
                    alt="Single Sign-On"
                    className=""
                    src="/images/features/timetastic-pro/sso.png"
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">Single Sign-On</h2>
                  <p className="c-features-grid__title">
                    Secure access with SAML-based SSO
                  </p>
                  <p>
                    Advanced security when you connect Timetastic through your
                    business class identity provider. Connect to Azure AD, Okta,
                    AuthO, OneLogin and Google Workspace to avoid authentication
                    headaches.
                  </p>
                </div>
              </div>

              <div className="c-features-grid__item">
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">Accruals</h2>
                  <p className="c-features-grid__title">Earn your leave days</p>
                  <p>
                    Only those with enough accrued leave can book some downtime,
                    making sure everyone gets their fair share of time off. With
                    this innovative tool, you can easily tailor personalised
                    leave policies.
                  </p>
                </div>
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Accrued leave"
                    className="c-features-grid__image"
                    src="/images/features/timetastic-pro/accrual-left.png"
                    width={430}
                    height={245}
                  />
                </div>
              </div>

              <div className="c-features-grid__item">
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Cap leave with leave type limits"
                    className="c-features-grid__image"
                    src="/images/features/timetastic-pro/leavetype-limits-blobs.png"
                    width={392}
                    height={306}
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">Capped leave</h2>
                  <p className="c-features-grid__title">Set clear boundaries</p>
                  <p>
                    Set a maximum limit on the amount of leave an employee can
                    take within a specified period; to keep your business
                    running smoothly and make sure everyone gets to enjoy a
                    well-deserved break.
                  </p>
                </div>
              </div>

              <div className="c-features-grid__item">
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">Burnout Board</h2>
                  <p className="c-features-grid__title">
                    Don’t fall foul to overworking
                  </p>
                  <p>
                    100% unique to Timetastic. See which team members are not
                    taking enough time off. Spot who’s at risk of burnout or
                    going to end up with too much allowance left at the end of
                    the year.
                  </p>
                </div>
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Burnout Board"
                    className="c-features-grid__image"
                    src="/images/features/timetastic-pro/burnout-board.png"
                    width={539}
                    height={261}
                  />
                  <img
                    loading="lazy"
                    alt="Burnout Icon"
                    className="c-features-grid__media-icon c-features-grid__media-icon--left"
                    src="/images/features/timetastic-pro/burnout-icon.svg"
                    width={70}
                    height={70}
                  />
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Absence Insights"
                    className="c-features-grid__image"
                    src="/images/features/timetastic-pro/absence-insights.png"
                    width={539}
                    height={272}
                  />

                  <img
                    loading="lazy"
                    alt="Insights Icon"
                    className="c-features-grid__media-icon c-features-grid__media-icon--right"
                    src="/images/features/timetastic-pro/insights-icon.svg"
                    width={70}
                    height={70}
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">
                    Absence Insights
                  </h2>
                  <p className="c-features-grid__title">
                    Keep a close eye on absence
                  </p>
                  <p>
                    Spot the peaks and troughs of time off. See how many
                    requests are still hanging around. Insights bring your
                    absence trends into sharp focus, showing you exactly what’s
                    going on at any point in time.
                  </p>
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">Integrations</h2>
                  <p className="c-features-grid__title">Microsoft Teams</p>
                  <p>
                    Fully integrated with Microsoft Teams. Get daily absence
                    summaries in your favourite channel and handle time off
                    requests direct via chat.
                  </p>
                </div>
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Absence requests in Slack"
                    className="c-features-grid__image"
                    src="/images/features/timetastic-pro/teams-daily-summary.png"
                    width={539}
                    height={302}
                  />
                  <img
                    loading="lazy"
                    alt="Microsoft Teams"
                    className="c-features-grid__media-icon--custom c-features-grid__media-icon--left"
                    src="/images/features/timetastic-pro/ms-teams-icon.png"
                    width={70}
                    height={70}
                  />
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Security"
                    className="c-features-grid__image"
                    src="/images/features/timetastic-pro/security.png"
                    width={539}
                    height={235}
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">Security</h2>
                  <p className="c-features-grid__title">
                    Business class data protection
                  </p>
                  <p>
                    We are fully GDPR compliant and have regular external
                    penetration tests. All personal data is encrypted and hosted
                    in the UK/EU. We continually monitor our security for your
                    peace of mind.
                  </p>
                  <p>
                    Learn more about:{" "}
                    <a
                      href="/legal/timetastic-data-security"
                      rel="nofollow noreferrer noopener"
                      target="blank"
                      className="u-em-link u-em-link--light"
                    >
                      <span>Data Security</span>
                    </a>{" "}
                    |{" "}
                    <a
                      href="/legal/privacy-policy"
                      rel="nofollow noreferrer noopener"
                      target="blank"
                      className="u-em-link u-em-link--light"
                    >
                      <span>Privacy</span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">Reviews</h2>
                  <p className="c-features-grid__title">
                    Don’t take our word for it
                  </p>
                  <p>
                    Thousands of people give us five star reviews. They talk
                    about saving time, ease of use, greater visibility and not
                    needing to fill in forms.
                  </p>
                </div>
                <div className="c-features-grid__media-pro">
                  <a
                    href="https://www.capterra.com/reviews/146655/Timetastic?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                    rel="nofollow noreferrer noopener"
                    target="_blank"
                    className="capterra"
                  >
                    {" "}
                    <img
                      loading="lazy"
                      border="0"
                      src="https://assets.capterra.com/badge/3fb93b4fa79a460bf121a4febd992c0c.png?v=2103571&p=146655"
                    />
                  </a>
                  <a
                    href="https://www.reviews.co.uk/company-reviews/store/timetastic"
                    rel="nofollow noreferrer noopener"
                    target="_blank"
                    className="reviews-io"
                  >
                    <img
                      loading="lazy"
                      src="https://media.reviews.co.uk/badge/timetastic.png"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <FeaturesCrossLinks hidden="Leave Types" />

          <FreeTrialAlt
            body="If you’re still wondering about something in Timetastic, or need to see how something works. Just shout, we’re here to help."
            buttonCopy="Start free trial today"
            title="If you’re ready, give it a try now"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default
